import type { SupabaseClient } from '@supabase/supabase-js'
import { createClient } from '@supabase/supabase-js'
import type { ReactNode } from 'react'
import { createContext } from 'react'

export const SupabaseContext = createContext<SupabaseClient | null>(null)

declare global {
  let __supabase: SupabaseClient | undefined
}

export const SupabaseProvider = ({
  supabaseUrl,
  supabaseKey,
  children,
}: {
  supabaseUrl: string
  supabaseKey: string
  children: ReactNode
}) => {
  const getSupabaseClient = () => {
    if (typeof window !== 'undefined') {
      if (!window.__supabase) {
        const client = createClient(supabaseUrl, supabaseKey)
        try {
          client.auth.refreshSession()
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {}
        window.__supabase = client
      }
      return window.__supabase
    } else {
      if (!global.__supabase) {
        const client = createClient(supabaseUrl, supabaseKey)
        global.__supabase = client
      }
      return global.__supabase
    }
  }

  return (
    <SupabaseContext.Provider value={getSupabaseClient()}>
      {children}
    </SupabaseContext.Provider>
  )
}
