import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toCamelCase } from '~/lib/utils'

class TS {
  key: string
  value: string

  constructor(key: string, value: string) {
    this.key = key
    this.value = value
  }

  translate(t: TFunction) {
    return t(this.key, this.value)
  }
}

class AllStrings {
  strings: TS[] = []

  add(ts: TS) {
    this.strings.push(ts)
  }

  json(t: TFunction) {
    const jsObj: Record<string, string> = {}
    this.strings.forEach(s => {
      jsObj[toCamelCase(s.key)] = s.translate(t)
    })
    return jsObj
  }
}

const all = new AllStrings()

all.add(new TS('error.boundary.title', 'Oh no!'))
all.add(new TS('error.boundary.header', 'Oops... something went wrong'))
all.add(new TS('error.boundary.body', 'We are already working on fixing it'))

all.add(new TS('menu.login', `Sign in or register`))
all.add(new TS('menu.logout', `Logout`))
all.add(new TS('menu.logout.tooltip', `Log out of your account`))
all.add(new TS('menu.nextExercise', `Next Exercise`))
all.add(new TS('menu.nextExercise.tooltip', `Your next exercise`))
all.add(new TS('menu.listDrills', `Drills`))
all.add(new TS('menu.listDrills.tooltip', `Drill list and editing`))
all.add(new TS('menu.listArticles', `Articles`))
all.add(new TS('menu.listArticles.tooltip', `Article list and editing`))
all.add(new TS('menu.listPrograms', `Programs`))
all.add(new TS('menu.listPrograms.tooltip', `Programs list and editing`))

all.add(new TS('login.error', 'An error occurred during login'))
all.add(new TS('login.title', `Sign in to your account`))
all.add(new TS('login.fields.email', `Email`))
all.add(new TS('login.fields.password', `Password`))
all.add(new TS('login.fields.rememberMe', `Remember me`))
all.add(new TS('login.buttons.forgotPassword', `Forgot password?`))
all.add(new TS('login.buttons.signin', `Sign in`))
all.add(new TS('login.buttons.noAccount', `Don't have an account?`))
all.add(new TS('login.buttons.signup', `Sign up`))
all.add(new TS('logout.error', 'An error occurred during logout'))

all.add(new TS('registration.error', 'An error occurred during registration'))
all.add(new TS('registration.title', `Sign up for your account`))
all.add(new TS('registration.fields.email', `Email`))
all.add(new TS('registration.fields.password', `Password`))
all.add(new TS('registration.buttons.signin', `Sign in`))
all.add(new TS('registration.buttons.haveAccount', `Already have an account?`))
all.add(new TS('registration.buttons.signup', `Sign up`))

export default function useStrings() {
  const { t } = useTranslation()
  return all.json(t)
}
